import React, { useContext } from 'react';

const modalScrollContext = React.createContext();
const modalScrollUpdateContext = React.createContext();

// screen type
const screenTypeContext = React.createContext();
const screenTypeUpdateContext = React.createContext();

// Zoom type
const zoomContext = React.createContext();
const zoomUpdateContext = React.createContext();

// vid playing
const vidplayContext = React.createContext();
const vidplayUpdateContext = React.createContext();

// swipers
const swiperContext = React.createContext();
const swiperUpdateContext = React.createContext();

export function useModalScroll() {
    return useContext(modalScrollContext);
}
export function useModalScrollUpdate() {
    return useContext(modalScrollUpdateContext);
}

// screen type
export function useScreenType() {
    return useContext(screenTypeContext);
}
export function useScreenTypeUpdate() {
    return useContext(screenTypeUpdateContext);
}

// zoom factor
export function useZoomFactor() {
    return useContext(zoomContext);
}
export function useZoomFactorUpdate() {
    return useContext(zoomUpdateContext);
}
// vid playing
export function useVidPlaying() {
    return useContext(vidplayContext);
}
export function useVidPlayingUpdate() {
    return useContext(vidplayUpdateContext);
}
// swipers
export function useSwipers() {
    return useContext(swiperContext);
}
export function useSwipersUpdate() {
    return useContext(swiperUpdateContext);
}


export function MixProvider({ children }) {
    
    const [modalScroll,setModalScroll] = React.useState(true);
    const [screenType,setScreenType] = React.useState(true);
    const [zoomFactor,setZoomFactor] = React.useState();
    const [vidPlaying,setVidPlaying] = React.useState(false);
    const [swipers,setSwipers] = React.useState(["iugwef"]);

    function setModScroll(_index) {
        setModalScroll(_index);
    } 
    function setScrType(_index) {
        setScreenType(_index);
    } 
    function setZoom(_zoom) {
        setZoomFactor(_zoom);
    } 
    function setVidPlay(_playing) {
        setVidPlaying(_playing);
    } 
    function setSwipe(_swiper) {
        setSwipers(_swiper);
    } 
    
    return (
        <swiperContext.Provider value="swipers">
            <swiperUpdateContext.Provider value={setSwipe}>
                <vidplayContext.Provider value={vidPlaying}>
                    <vidplayUpdateContext.Provider value={setVidPlay}>
                        <zoomContext.Provider value={zoomFactor}>
                            <zoomUpdateContext.Provider value={setZoom}>
                                <screenTypeContext.Provider value={screenType}>
                                    <screenTypeUpdateContext.Provider value={setScrType}>
                                        <modalScrollContext.Provider value={modalScroll}>
                                            <modalScrollUpdateContext.Provider value={setModScroll}>
                                                {children}
                                            </modalScrollUpdateContext.Provider>
                                        </modalScrollContext.Provider>
                                    </screenTypeUpdateContext.Provider>
                                </screenTypeContext.Provider>
                            </zoomUpdateContext.Provider>
                        </zoomContext.Provider>
                    </vidplayUpdateContext.Provider>
                </vidplayContext.Provider> 
            </swiperUpdateContext.Provider>
        </swiperContext.Provider>  
    )
}
