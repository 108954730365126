import React, { useContext } from 'react';

const modalTypeContext = React.createContext();
const modalTypeUpdateContext = React.createContext();

const modalContext = React.createContext();
const modalUpdateContext = React.createContext();

const modalContentContext = React.createContext();
const modalContentUpdateContext = React.createContext();


export function useModalType() {
    return useContext(modalTypeContext);
 }
 
 export function useModalTypeUpdate() {
     return useContext(modalTypeUpdateContext)
 }

export function useModal() {
   return useContext(modalContext);
}

export function useModalUpdate() {
    return useContext(modalUpdateContext)
}

export function useModalContent() {
    return useContext(modalContentContext);
 }
 
 export function useModalContentUpdate() {
     return useContext(modalContentUpdateContext);
 }


export function ModalProvider({ children }) {

    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [content,setContent] = React.useState();
    const [type,setType] = React.useState();

    function setModalType(_type) {
        setType(_type);
    }
    function setModal(_vid) {
        const isOpen = !modalIsOpen
        setIsOpen(isOpen);
    }
    function setModalContent(_content) {
        setContent(_content);
        setIsOpen(true)
    }  
    
    return (
        <modalTypeContext.Provider value={type}>
            <modalTypeUpdateContext.Provider value={setModalType}>
                <modalContentContext.Provider value={content}>
                    <modalContentUpdateContext.Provider value={setModalContent}>
                        <modalContext.Provider value={modalIsOpen}>
                            <modalUpdateContext.Provider value={setModal}>
                                {children}
                            </modalUpdateContext.Provider>
                        </modalContext.Provider>
                    </modalContentUpdateContext.Provider>
                </modalContentContext.Provider>
            </modalTypeUpdateContext.Provider>
        </modalTypeContext.Provider>
    )
}
