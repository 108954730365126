import React from 'react';
import { useScreenIndex, useScreenIndexUpdate, useModuleIndex, useModuleIndexUpdate, useModuleScreenCount, useModuleScreenCountUpdate } from "../../components/context/ModuleContext";
import { useScreenType, useScreenTypeUpdate, useSwipers, useSwipersUpdate } from "../../components/context/MixContext";
import { useScreenShow, useScreenShowUpdate } from "../../components/context/ScreenContext";



import './Submenu.css'

const Submenu = (props) => {

    // Screen count
    const getScreenCount = useModuleScreenCount();
    const subArray = Array.from(Array(parseInt(getScreenCount)).keys());
    const titleArray = ["CNS", "Pelvis", "Thorax", "Abdomen","Musculoskeletal"]

    // module index
    const getModuleIndex = useModuleIndex();

    // screen index
    const getScreenIndex = useScreenIndex();
    const setScreenIndex = useScreenIndexUpdate();

    // Screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();

    // Screen show
    const getScreenShow = useScreenShow();
    const setScreenShow = useScreenShowUpdate();

    // swipers
    const getSwipers = useSwipers();
    const setSwipers = useSwipersUpdate();
    
    const submenuClick = (e) => {
        
        const elId = parseInt(e.target.getAttribute('data-id'));
        setScreenIndex(elId);
        props.closesub();
        setScreenShow(false);
        setScreenType("library");

    }

    return (
        <div>
            <div className="submenu-wrap">
                <div className="submenu-el text">
                    <h1>{ titleArray[getModuleIndex] }</h1>
                    <div className="submenu-nav">
                        {subArray.map((a, tdIdx, arr) => 
                            <div className="submenu-item" key={tdIdx + tdIdx} onClick={submenuClick} data-id={tdIdx}><p>Patient case {tdIdx+1}</p></div>
                        )}
                    </div>
                </div>
                <div className={`submenu-el image fig-${getModuleIndex}`}>
                </div>

            </div>
        </div>
    );
};

export default Submenu;