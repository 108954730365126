import React, {useState, useEffect} from 'react';
import { useData } from "../../components/context/DataContext";
import { useScreenShow, useScreenShowUpdate } from "../../components/context/ScreenContext";
import { useModuleIndex, useModuleIndexUpdate, useModuleScreenCount, useModuleScreenCountUpdate } from "../../components/context/ModuleContext";
import { useModalType, useModalTypeUpdate, useModalUpdate } from "../../components/context/ModalContext";
import { useScreenType, useScreenTypeUpdate } from "../../components/context/MixContext";
import { motion } from "framer-motion";



import './MenuLink.css'

const MenuLink = (props) => {
    // data
    const json = useData();

    // Show modal
    const popupModalSet = useModalUpdate();

    // Screen show
    const getScreenShow = useScreenShow();
    const setScreenShow = useScreenShowUpdate();

    // module type
    const getModalType = useModalType();
    const setModalType = useModalTypeUpdate();

    // module index
    const getModuleIndex = useModuleIndex();
    const setModuleIndex = useModuleIndexUpdate();

    // Screen count
    const getScreenCount = useModuleScreenCount();
    const setScreenCount = useModuleScreenCountUpdate();

    // Screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();


    const handleClick = () => {
        setModalType("submenu");
        setScreenCount(props.count)
        setModuleIndex(props.indx);
        popupModalSet(true);
    }

    return (
        
        <motion.div className="link-wrap"
            initial={{ opacity: 0, scale: .5 }}
            animate={{ opacity: 1, scale: 1 }} 
            transition={{ delay: props.delay, duration: .5 }}
        >
            <div className="link-click-wrap" onClick={handleClick}>
                <div className="link-img">
                    <img src={require(`../../assets/images/mod${props.indx}-img.png`)} width="100%" alt="Menu" />
                </div>
                <div className="link-title">
                    {json && json.screen[props.indx].title} 
                </div>
                <div className="link-click"></div>
            </div>
        </motion.div>

    );
};

export default MenuLink;