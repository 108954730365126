import { ModalProvider } from "./components/context/ModalContext";
import { MixProvider } from "./components/context/MixContext";
import { DataProvider } from "./components/context/DataContext";
import { ScreenProvider } from "./components/context/ScreenContext";
import { ModuleProvider } from "./components/context/ModuleContext";
import PopupModal from "./components/PopupModal/PopupModal";


import { useScreenShow, useScreenShowUpdate } from "./components/context/ScreenContext";

import { useModal, useModalUpdate, useModalContentUpdate } from "./components/context/ModalContext";







import { LocaleProvider } from "./components/context/LocalContext";

import './assets/webFonts/GuerbetEluciremQuiz.css'
import './App.css';

import Content from './layout/Content/Content';


function App() {


      // Screen show
      const getScreenShow = useScreenShow();
      const setScreenShow = useScreenShowUpdate();
      const setModalState = useModalUpdate();


  return (
    <>
      <ScreenProvider>
        <LocaleProvider>
          <DataProvider>
            <MixProvider>
              <ModuleProvider>
                <ModalProvider>
                  <Content />
                  <PopupModal />
                </ModalProvider>
              </ModuleProvider>
            </MixProvider>
          </DataProvider>
        </LocaleProvider>
      </ScreenProvider>
    </>
  );
}

export default App;
