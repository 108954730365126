import React, { useState } from 'react';
import { useScreenShowUpdate } from "../../components/context/ScreenContext";
import { useScreenType, useScreenTypeUpdate } from "../../components/context/MixContext";

import './Header.css'

const Header = () => {

    const [scrollPos, setScrollPos] = useState(0);

    const handleClick = () => {
        setScreenShow(true);
    }

    // Screen show
    const setScreenShow = useScreenShowUpdate();

    // Screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();


    window.addEventListener('scroll', () => {
        setScrollPos(window.scrollY);
    });

    return (
        <header id="header" className={scrollPos > 5 ? "" : "" }>
            <div className="header-wrap">
                <div className="logo">
                    <img src={require(`../../assets/images/logo.png`)} height="32px" alt="logo" />
                </div>
                {/* <div className="home-btn" onClick={handleClick}>Home</div> */}
                {getScreenType !== "menu" ?
                    <div className="home-btn" onClick={handleClick}>Home</div>
                    :
                    null
                }
            </div>
        </header>
    );
};

export default Header;