import React from 'react';
import { useSwiper } from 'swiper/react';
import { useModalUpdate, useModalTypeUpdate, useModalContentUpdate } from "../../../components/context/ModalContext";

import '../Slides.css'

const Slide01 = (props) => {

    const _swiper = useSwiper();
    const popupModalSet = useModalUpdate(true);

    // modal type
    const setModalType = useModalTypeUpdate();

    const comparisonClick = (_idx) => {
        setModalType("comparison");
        popupModalSet(true);
    }

    return (
        <div className="slide-content">
            <div className="content-head">
                <div className="slide-title"><h2>Musculoskeletal — Patient case 2</h2></div>
                <div className="nav">
                    <div className="btn screen-nav prev" onClick={() => props.slideprevplease(_swiper)}><img src={require("../../../assets/images/chevron.svg").default} width="10px" height="10px" alt="Chevron" /> <p>Last case</p></div>
                    <div className="btn screen-nav next" onClick={() => props.slidenextplease(_swiper)}><p>Next case</p> <img src={require("../../../assets/images/chevron.svg").default} width="10px" height="10px" alt="Chevron" /></div>
                </div>
            </div>
            <div className="scan-img-wrap">
                <div className="scan-img">
                    <img src={require(`../../../assets/images/library/musculoskeletal/slide-01.png`)} width="100%" alt="Option" />
                </div>
                <div className="btn btn-square comparison-btn" onClick={(e) => comparisonClick()}><img src={require("../../../assets/images/chevron.svg").default} width="10px" height="10px" alt="Chevron" /> <p>View comparison to a contrast agent with a full dose of gadolinium</p></div>
            </div>
        </div>
    );
};

export default Slide01;