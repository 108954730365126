import React from 'react';  

import { useModal, useModalUpdate, useModalContentUpdate } from "../../components/context/ModalContext";
import { useScreenShow, useScreenShowUpdate } from "../../components/context/ScreenContext";
import { useScreenType, useScreenTypeUpdate } from "../../components/context/MixContext";


import './Footer.css'

const Footer = () => {

    

    // const modalState = useModal(true);
    const setModalState = useModalUpdate();
    const popupModalSet = useModalUpdate(true);
    const setModalIndex = useModalContentUpdate();

    // Screen show
    const getScreenShow = useScreenShow();
    const setScreenShow = useScreenShowUpdate();

    // Screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();
    

    const handleClick = (_idx) => {
        setModalIndex(_idx);
        popupModalSet(true);
    }



    return (
        <footer>
            <div className="footer-wrap">
                {/* <div className="footer-footnote-wrap"> */}
                <div className={ getScreenType !== "library" ? "footer-footnote-wrap" : "footer-footnote-wrap hide-footnote" }>
                    <div className="footer-text footer-txt1">
                        <p>Elucirem is indicated in adults and children aged 2 years and older for contrast-enhanced magnetic resonance imaging (MRI) to improve detection and visualization of pathologies with disruption of the blood-brain-barrier (BBB) and/or abnormal vascularity of the brain, spine, and associated tissues of the central nervous system (CNS); the liver, kidney, pancreas, breast, lung, prostate, and musculoskeletal system.</p>
                        <p><b>1.</b> Elucirem Summary of Product Characteristics. 2023.</p>
                    </div>
                    <div className="footer-text footer-txt2">
                        <p className="ae">Adverse events should be reported to your local regulatory authority and Guerbet office.</p>
                    </div>
                </div>
                <div className="footer-bar">
                    <div className="footer-logo">
                        <img src={require("../../assets/images/guerbet-logo.png")} width="192" alt="Guerbet logo" />
                    </div>
                    <div className="footer-text">
                    <p>This information is intended for EU HCPs only. Indications and presentations may differ from country to country. For complete information, please refer to your local <a href="https://www.elucirem-global.com/media/x41o4k41/mentions-l%C3%A9gales-abr%C3%A9g%C3%A9es-corporate-elucirem.pdf" target="_blank">SmPC</a>. <br />Results from case studies are not necessarily predictive of results in other cases. Results in other cases may vary. Trademarks are the property of their respective owners.</p>
                        <p className="jobcode">P24002596 | Date of preparation: January 2024</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;