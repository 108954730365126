import React, { useEffect } from 'react';
import { useData } from "../../components/context/DataContext";
import { useModuleIndex, useScreenIndex } from "../../components/context/ModuleContext";
import { useModalType, useModal, useModalUpdate, useModalContentUpdate } from "../../components/context/ModalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { renderFormattedText } from "../../utils/helpers";




import './ModalContent.css';
// CNS
import Slide00Popup from '../../layout/Slides/CNS/Slide00Popup';
import Slide01Popup from '../../layout/Slides/CNS/Slide01Popup';
import Slide02Popup from '../../layout/Slides/CNS/Slide02Popup';
import Slide03Popup from '../../layout/Slides/CNS/Slide03Popup';
import Slide04Popup from '../../layout/Slides/CNS/Slide04Popup';
import Slide05Popup from '../../layout/Slides/CNS/Slide05Popup';
import Submenu from '../Submenu/Submenu';

const ModalContent = (props) => {

    // const _mdlContent = useModalContent();
    const json = useData();
    const popupModalSet = useModalUpdate(true);

    // module type
    const getModalType = useModalType();

    // module index
    const getModuleIndex = useModuleIndex();

    // screen index
    const getScreenIndex = useScreenIndex();



    // console.log(json && json.screen[getModuleIndex].data[getScreenIndex][1]);
    // console.log(getScreenIndex);

    // info.innerHTML = json && json.screen[getModuleIndex].data[_idx][0].txt;


    // const currentComp = getScreenIndex && json.screen[getModuleIndex].data[getScreenIndex][0].txt;




    const popupArray = [
        [<Slide00Popup />, <Slide01Popup />, <Slide02Popup />, <Slide03Popup />, <Slide04Popup />, <Slide05Popup />]
    ]

    useEffect(() => {
        const modalID = document.getElementById("react-modal");
        if ( getModalType === "comparison" ) {
            modalID.style.maxWidth = "1220px";
        }
    }, []);

    if ( getModalType === "submenu" ) {
        return (
            <div id="submenu">
                <div className="modal-header-transparent">
                    <div className="close-btn" onClick={props.close}><FontAwesomeIcon icon={faTimes} /></div>
                </div>
                <Submenu closesub={props.close} />
            </div>
        );
    }

    if ( getModalType === "comparison" ) {

        const currentComp = json && json.screen[getModuleIndex].data[getScreenIndex][0].img1;


        return (
            <div id="comparison">
                <div className="modal-header-transparent">
                    <div className="close-btn" onClick={props.close}><FontAwesomeIcon icon={faTimes} /></div>
                </div>
                <div id="comparison-wrap" className="comparison-wrap">
                    <div className="column-wrap">
                        <div className="comparison-col left-col">
                            <div className="img">
                                <img src={require(`../../assets/images/${json && json.screen[getModuleIndex].data[getScreenIndex][0].img1}`)} width="100%" alt="Ist injection" />
                            </div>
                            <div className="mob-text1">
                                {json && renderFormattedText(json.screen[getModuleIndex].data[getScreenIndex][0].text1)}
                            </div>
                        </div>
                        <div className="day">
                            <img className="day-img desktop-day-img" src={require(`../../assets/images/${json && json.screen[getModuleIndex].data[getScreenIndex][0].divider}`)} width="100%" alt="Days" />
                            <img className="day-img mobile-day-img" src={require(`../../assets/images/${json && json.screen[getModuleIndex].data[getScreenIndex][0].dividermob}`)} width="100%" alt="Days" />
                        </div>
                        <div className="comparison-col right-col">
                            <div className="img">
                                <img src={require(`../../assets/images/${json && json.screen[getModuleIndex].data[getScreenIndex][0].img2}`)} width="100%" alt="Ist injection" />
                            </div>
                            <div className="mob-text2">
                                {json && renderFormattedText(json.screen[getModuleIndex].data[getScreenIndex][0].text2)}
                            </div>
                        </div>
                    </div>
                    <div className="column-wrap desktop-column-wrap">
                        <div className="comparison-col left-col">
                            <div className="img-text">{json && renderFormattedText(json.screen[getModuleIndex].data[getScreenIndex][0].text1)}</div>
                        </div>
                        <div className="day"></div>
                        <div className="comparison-col right-col">
                            <div className="img-text">{json && renderFormattedText(json.screen[getModuleIndex].data[getScreenIndex][0].text2)}</div>
                        </div>
                    </div>
                    <div className="footnote">{json && renderFormattedText(json.screen[getModuleIndex].data[getScreenIndex][0].footnote)}</div>

                    { json && json.screen[getModuleIndex].data[getScreenIndex][0].key != "" ? renderFormattedText(`<div class='key'>${json.screen[getModuleIndex].data[getScreenIndex][0].key}</div>`) : "" }


                </div>
            </div>
        );
    }

};

export default ModalContent;