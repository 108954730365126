import React, { useState } from 'react';
import { useData } from "../../../components/context/DataContext";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFlip, EffectFade, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { useModuleIndex, useScreenIndex, useScreenIndexUpdate } from "../../../components/context/ModuleContext";
import { useScreenType, useScreenTypeUpdate, useSwipers, useSwipersUpdate } from "../../../components/context/MixContext";
import { useScreenShowUpdate } from "../../../components/context/ScreenContext";

import { Virtual } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/virtual';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-fade';

import '../Slides.css'

import PelvisSlide00 from "../../../layout/Slides/Pelvis/Slide00";

const PelvisSlideHolder = () => {

    // Screen show
    const setScreenShow = useScreenShowUpdate();

    // data
    const json = useData();

    // module index
    const getModuleIndex = useModuleIndex();
    const setModuleIndex = useScreenIndexUpdate();

    // currentIndex
    let [currentIndex, setCurrentIndex] = useState(0)

    // screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();

    // screen index
    const getScreenIndex = useScreenIndex();
    const setScreenIndex = useScreenIndexUpdate();

    // swipers
    const getSwipers = useSwipers();
    const setSwipers = useSwipersUpdate();

    const prevSlidePlease = (_swipe) => {
        const idx = currentIndex;
        setCurrentIndex(idx-1);
        checkNav(_swipe, false, idx-1);
    }
    const nextSlidePlease = (_swipe) => {
        const idx = currentIndex;
        setCurrentIndex(idx+1);
        checkNav(_swipe, true, idx+1);
    }

    const checkNav = (_swiper, _next, _idx) => {
        if ( _next ) {
            if ( currentIndex === _swiper.slides.length-1 ) {
                setScreenShow(true);
            } else {
                _swiper.slideNext();
                const info = document.getElementById("slide-data");
                info.innerHTML = json && json.screen[getModuleIndex].data[_idx][0].txt;
                setScreenIndex(_idx);
            }
        } else {
            if ( currentIndex === 0 ) {
                setScreenShow(true);
            } else {
                _swiper.slidePrev();
                const info = document.getElementById("slide-data");
                info.innerHTML = json && json.screen[getModuleIndex].data[_idx][0].txt;
                setScreenIndex(_idx);
            }
        }
    }

    return (
        <Swiper
            observer={true}
            observeParents={true}
            autoHeight= {true}
            modules={[Navigation, EffectFade, EffectFlip, A11y, Virtual]}
            // spaceBetween={1}
            slidesPerView={1}
            noSwiping={true}  
            noSwipingClass='noswiper'
            centeredSlides
            onSlideChange={(swiper) => {
                // 
            }}
            onAfterInit={(swiper) => {
                swiper.slideTo(getScreenIndex, .2);
                setCurrentIndex(getScreenIndex)
                const info = document.getElementById("slide-data");
                info.innerHTML = json && json.screen[getModuleIndex].data[getScreenIndex][0].txt;
            }}
            onBeforeSlideChangeStart={(swiper) => {
                // 
            }}
            onSlideChangeTransitionEnd={(swiper) => {
                
            }}
            onReachEnd={(swiper) => {
                // console.log("reach end");
            }}
        >

            <SwiperSlide className="noswiper" key={0} virtualIndex={0}>
                <PelvisSlide00 slideprevplease={prevSlidePlease} slidenextplease={nextSlidePlease} mod="1" screen="0" />
            </SwiperSlide>
        </Swiper>
    );
};

export default PelvisSlideHolder;