import React, { useState, useRef, useEffect } from 'react';
import { useModuleIndex, useModuleIndexUpdate } from "../context/ModuleContext";
import { useScreenType, useScreenTypeUpdate, useZoomFactor, useZoomFactorUpdate, useVidPlaying, useVidPlayingUpdate } from "../context/MixContext";
// import { Virtual } from 'swiper/modules';
// import Swiper core and required modules
import { Navigation, EffectFlip, EffectFade, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion";
import { useIdleTimerContext } from 'react-idle-timer';


import CNSSlideHolder from '../../layout/Slides/CNS/CNSSlideHolder';
import PelvisSlideHolder from '../../layout/Slides/Pelvis/PelvisSlideHolder';
import ThoraxSlideHolder from '../../layout/Slides/Thorax/ThoraxSlideHolder';
import AbdomenSlideHolder from '../../layout/Slides/Abdomen/AbdomenSlideHolder';
import MusculoskeletalSlideHolder from '../../layout/Slides/Musculoskeletal/MusculoskeletalSlideHolder';



// Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/virtual';
// import 'swiper/css/effect-flip';
// import 'swiper/css/effect-fade';

import './Library.css'






const Library = (props) => {

    // Screen type
    // const getScreenType = useScreenType();
    // const setScreenType = useScreenTypeUpdate();

    // module index
    const getModuleIndex = useModuleIndex();
    const setModuleIndex = useModuleIndexUpdate();
  
    return (
        <motion.div id="Library" className="library"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }} 
            transition={{ delay: .2, duration: .5 }}>

                <div className={getModuleIndex === "1" ? "swiper-holder oneslide" : "swiper-holder"}>
                    {getModuleIndex === "0" ? <CNSSlideHolder /> : null }
                    {getModuleIndex === "1" ? <PelvisSlideHolder /> : null }
                    {getModuleIndex === "2" ? <ThoraxSlideHolder /> : null }
                    {getModuleIndex === "3" ? <AbdomenSlideHolder /> : null }
                    {getModuleIndex === "4" ? <MusculoskeletalSlideHolder /> : null }
                </div>
                <div id="slide-data" className="library-right-col"></div>

        </motion.div>
    );
};

export default Library;