import React, { useContext } from 'react';

const moduleContext = React.createContext();
const moduleUpdateContext = React.createContext();

const screenContext = React.createContext();
const screenUpdateContext = React.createContext();

const moduleScreenCountContext = React.createContext();
const moduleScreenCountUpdateContext = React.createContext();

export function useModuleIndex() {
   return useContext(moduleContext);
}

export function useModuleIndexUpdate() {
    return useContext(moduleUpdateContext)
}
// 
export function useScreenIndex() {
    return useContext(screenContext);
 }
 
 export function useScreenIndexUpdate() {
     return useContext(screenUpdateContext)
 }
// 
export function useModuleScreenCount() {
    return useContext(moduleScreenCountContext);
 }
 
 export function useModuleScreenCountUpdate() {
     return useContext(moduleScreenCountUpdateContext)
 }

export function ModuleProvider({ children }) {

    const [module, setModule] = React.useState(null);
    const [screenIndex, setScreen] = React.useState(null);
    const [screenCount, setScreenCount] = React.useState(null);

    function setModuleIndex(_module) {
        setModule(_module);
    }
    function setScreenIndex(_screen) {
        setScreen(_screen);
    }
    function setCount(_count) {
        setScreenCount(_count);
    }
    
    return (


        <moduleScreenCountContext.Provider value={screenCount}>
            <moduleScreenCountUpdateContext.Provider value={setCount}>

                <moduleContext.Provider value={module}>
                    <moduleUpdateContext.Provider value={setModuleIndex}>

                        <screenContext.Provider value={screenIndex}>
                            <screenUpdateContext.Provider value={setScreenIndex}>

                                {children}
                                
                            </screenUpdateContext.Provider>
                        </screenContext.Provider>
                        
                    </moduleUpdateContext.Provider>
                </moduleContext.Provider>

            </moduleScreenCountUpdateContext.Provider>
        </moduleScreenCountContext.Provider>

    )
}
